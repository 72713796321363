

export const getSubdomain = (url: string) => {
  if (!url) return ''
  let domain = url
  if (url && url.includes('://')) {
    domain = url.split('://')[1]
  }
  const subdomain = domain.split('.')[0]
  return subdomain
}
