import { Tenants } from '@/interfaces/Tenants'
import { colorsDark, colorsLight } from '@/styles/colors'

export const DEFAULT_TENANT: Tenants = {
  name: 'araraia',
  logo: '/logo-araraia.svg',
  logo_extended: null,
  cnpj: '',
  id: 0,
  theme: colorsLight,
  theme_dark: colorsDark,
  image_background: '',
  url: ''
}
